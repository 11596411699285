import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const Venues = (props) => {
  const venues = props.data.allContentfulVenue.edges;

  return (
    <Layout location={props.location}>
      <div className="columns" style={{ marginTop: "4rem" }}>
        <div className="column is-full" style={{ marginBottom: "2rem" }}>
          <h1 className="title is-1">Venues</h1>
        </div>
      </div>

      {venues.map((data) => (
        <Venue
          key={data.node.id}
          name={data.node.name}
          text={data.node.description.description}
          website={data.node.website}
          photo={data.node.photo.file.url}
        />
      ))}
    </Layout>
  );
};

const Venue = ({ name, text, website, photo }) => (
  <div className="columns" style={{ marginBottom: "3rem" }}>
    <div className="column is-half">
      <img src={photo} alt={name} />
    </div>

    <div className="column is-half">
      <h2 className="subtitle is-3">
        <a href={website} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </h2>
      <p>{text}</p>
    </div>
  </div>
);

export const pageQuery = graphql`
  query {
    allContentfulVenue {
      edges {
        node {
          id
          name
          description {
            description
          }
          website
          photo {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Venues;
